// src/pages/Home.js
import React from 'react';
import "../styles/Projects.css"
import ProjectCard from './ProjectCard'; // Adjust the path as necessary
import SpinNStone from '../assets/spinnstone.png'; // Make sure the path to your image is correct
import HiddenGems from '../assets/hiddengem.png'; // Make sure the path to your image is correct
import NatureNet from '../assets/naturenet.png'; // Make sure the path to your image is correct
import NatureNetConfig from '../assets/naturenetconfig.png'; // Make sure the path to your image is correct
import NatureNetLogin from '../assets/naturenetlogin.png'; // Make sure the path to your image is correct
import NatureNetLogs from '../assets/naturenetlogs.png'; // Make sure the path to your image is correct
import NatureNetSettings from '../assets/naturenetsettings.png'; // Make sure the path to your image is correct
import HiddenGemsLogin from '../assets/hiddengemp.png'; // Make sure the path to your image is correct
import SNS1 from '../assets/SNSmain.png';
import SNS2 from '../assets/SNS2.png';
import SNS3 from '../assets/SNS3.png';
import SNS4 from '../assets/SNS4.png';
import SNS5 from '../assets/SNS5.png';
import SNS6 from '../assets/SNS6.png';
import SNS7 from '../assets/SNSfinal.png';

function Projects() {
    return (
        <div className="projects-page-container">
            <ProjectCard
                title="NatureNet"
                description="The application alerts users of dangerous wildlife with SMS alerts after taking in a continous video of their surroundings. The tech stack is React, Flask, OpenCV, SMS API and prisma. As the frontend engineer, I made 4 pages and implemented the Google OAuth sign in."
                imageUrl={NatureNet}
                extraImages={[NatureNetLogin, NatureNetLogs, NatureNetConfig, NatureNetSettings]}
            />
            <ProjectCard
                title="Spin N' Stone"
                description="This is a point of sales application that can be used for managers, servers, and customers. The tech stack is React and Flask. For this project, I acted as the project manager while using the agile methodology. I worked on both frontend and backend components for this project."
                imageUrl={SpinNStone}
                extraImages={[SNS1, SNS2, SNS3, SNS4, SNS5, SNS6, SNS7]}
            />

            <ProjectCard
                title="Hidden Gems"
                description="This is a movie recommendation application. After answering a few questions, users will be recommended a movie and will have the option to store and save the movies for later. Currently, I only have the login page mocked up."
                imageUrl={HiddenGems}
                extraImages={[HiddenGemsLogin]}
            />
        </div>


    );

}

export default Projects;
