import React from 'react';
import '../styles/Home.css'; // Make sure the path to your CSS file is correct
import Selfie from '../assets/face.png';
import PythonIcon from '../assets/python.jpg';
import ReactIcon from '../assets/react.png';
import awsIcon from '../assets/aws.png';
import mongodbIcon from '../assets/mongodb.svg';
import githubIcon from '../assets/github.png';
import linkedinIcon from '../assets/linkedin.webp';

function Home() {
    return (
        <div className="home-container">
            <div className="main-content">
                <div className="content">
                    <div className="left-section">
                        <h1>Full-stack Developer</h1>
                        <p>Why do programmers mix up christmas and halloween? Because Oct 31 == Dec 25</p>
                        <div className="icons">
                            {/* Wrap images in <a> tags with URLs to your profiles */}
                            <a href="https://github.com/AndrewChian2" target="_blank" rel="noopener noreferrer">
                                <img src={githubIcon} alt="GitHub" className="icon-placeholder" />
                            </a>
                            <a href="https://www.linkedin.com/in/andrew-chian-3aa2381ab/" target="_blank" rel="noopener noreferrer">
                                <img src={linkedinIcon} alt="LinkedIn" className="icon-placeholder" />
                            </a>
                        </div>
                    </div>
                    <div className="right-section">
                        <img src={Selfie} alt="Selfie" className="image-placeholder" />
                    </div>
                </div>
                <div className="tech-stack">
                    <div className="tech-stack-label">Tech Stack |</div>
                    <img src={PythonIcon} alt="Python" className="tech-icon-placeholder" />
                    <img src={ReactIcon} alt="React" className="tech-icon-placeholder" />
                    <img src={mongodbIcon} alt="MongoDB" className="tech-icon-placeholder" />
                    <img src={awsIcon} alt="AWS" className="tech-icon-placeholder" />
                </div>
            </div>
        </div>
    );
}

export default Home;
