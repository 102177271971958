import React, { Component } from 'react';
import '../styles/Production.css';  // Assuming styles are defined in this CSS file

class ProductionPage extends Component {
    constructor(props) {
        super(props);
        // Initialize state with a default list of steps and sub-steps
        this.state = {
            steps: [
                {
                    title: "Home Page",
                    details: ["After taking inspiration from other portfolio websites, I wanted my home page to be minimalistic and clean", "I added essential links to my home page", "I listed the tech stack I am most comfortable with"]
                },
                {
                    title: "About Page",
                    details: ["Here is where I would go more in depth about myself", "The most trouble I had on this page was styling and ordering the left hand images"]
                },
                {
                    title: "Projects",
                    details: ["On this page I listed out the projects I'm most proud of. This page took me the longest to implement because of styling and alignment", "I chose to have a drop down of additional images rather than taking you to another page because I felt like it would be smoother and more intuitive", "Under more information, I may add more details about each project in the future"]
                },
                {
                    title: "Contact",
                    details: ["I used emailJS for the implementation of this page", "Initially, emails were not sending properly. After troubleshooting, I realized that I had not authorized the site with enough permissions", "After testing, I realized it is important to include a message telling the user the response was properly submitted"]
                },
            ]
        };
    }

    render() {
        return (
            <div className="production-page">
                <h1>How I Built This Site</h1>
                <ul>
                    {this.state.steps.map((step, index) => (
                        <li key={index} className="step-item">
                            <h3>{step.title}</h3>
                            <ul className="details-list">
                                {step.details.map((detail, detailIndex) => (
                                    <li key={detailIndex}>{detail}</li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default ProductionPage;
