// src/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import "./styles/Navbar.css"

function Navbar() {
    return (
        <nav className="navbar">
            <div className="nav-items">
                <Link to="/" className="nav-item">Home</Link>
                <Link to="/about" className="nav-item">About</Link>
                <Link to="/projects" className="nav-item">Projects</Link>
                <Link to="/production" className='nav-item'>Production</Link>
                <Link to="/contact" className='nav-item'>Contact</Link>
            </div>
        </nav>
    );
}

export default Navbar;
