import React, { useState, useRef } from 'react';
import "../styles/Contact.css";
import emailjs from '@emailjs/browser';

const Contact = () => {
    const form = useRef();
    const [message, setMessage] = useState(""); // State to store messages to the user

    const sendEmail = (e) => {
        e.preventDefault();
        setMessage(""); // Clear previous messages

        const serviceId = process.env.REACT_APP_EMAILJS_SERVICEID;
        const templateId = process.env.REACT_APP_EMAILJS_TEMPLATEID;
        const userId = process.env.REACT_APP_EMAILJS_USERID;



        emailjs
            .sendForm(serviceId, templateId, form.current, {
                publicKey: userId,
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    setMessage("Thank you for your message!");
                    e.target.reset();
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    console.log(process.env.REACT_APP_EMAILJS_SERVICEID);

                    console.log(process.env.REACT_APP_EMAILJS_TEMPLATEID);
                    console.log(process.env.REACT_APP_EMAILJS_USERID);
                    setMessage("Failed to send message, please try again."); // Set error message
                },
            );
    };

    return (
        <section className="container">
            <h2 className="--text-center">Contact</h2>
            <form ref={form} onSubmit={sendEmail} className="--form-control --card --flex-center --dir-column">
                <input type="text" placeholder="Full Name" name="user_name" required />
                <input type="email" placeholder="Email" name="user_email" required />
                <input type="text" placeholder="Subject" name="subject" required />
                <textarea name="message" cols="30" rows="10" placeholder="Your message here"></textarea>
                <button className="--btn --btn-primary">Send Message</button>
                <div className="feedback-message">{message}</div> {/* Display feedback message to the user */}
            </form>
        </section>
    );
}

export default Contact;
