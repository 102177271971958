import React, { useState } from 'react';
import '../styles/ProjectCard.css';

function ProjectCard({ title, description, imageUrl, extraImages }) {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className="project-card-container">
            <div className="project-image-wrapper">
                <img
                    src={imageUrl}
                    alt={title}
                    className={`project-image ${isExpanded ? 'hide' : ''}`} // Conditional class application
                />
            </div>
            <div className="project-description-wrapper">
                <h2 className="project-title">{title}</h2>
                <p className="project-description">{description}</p>
                <button
                    className="expand-button"
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    {isExpanded ? 'Hide Images' : 'Show More Images'}
                </button>
                {isExpanded && (
                    <div className="additional-images-container">
                        {extraImages.map((imgSrc, index) => (
                            <img key={index} src={imgSrc} alt={`${title} extra ${index + 1}`} className="extra-project-image" />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProjectCard;
