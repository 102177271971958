import React from 'react';
import '../styles/About.css';
import Picture from '../assets/face.png';
import OrchidPic from '../assets/orchidpic.jpg';
import FriendPic from '../assets/friendpic.jpg';
import ZiplinePic from '../assets/zipline.jpg';

function About() {
    return (
        <div className="about-container">
            <div className="box image-box">
                {/* Left column for two images */}
                <div className="image-column">
                    <img src={Picture} alt="About Me" className="about-image" />
                    <img src={OrchidPic} alt="Orchid" className="about-image" />
                </div>
                {/* Right column for two images */}
                <div className="image-column">
                    <img src={ZiplinePic} alt="Ziplining Adventure" className="about-image" />
                    <img src={FriendPic} alt="Friend" className="about-image" />
                </div>
            </div>
            <div className="box info-box">
                <h2 style={{ color: 'blue' }}>About Me</h2>
                <p><strong>Driven Full Stack Developer with a Passion for Building and Learning</strong></p>
                <p>Hello! I’m a full stack developer specializing in creating dynamic and engaging web applications. I have a keen interest in technology and its potential to solve real-world problems, which drives my passion for coding and continuous learning. My background includes significant experience in team-oriented environments, where my interpersonal communication skills and collaborative spirit have allowed me to excel and lead projects to successful completion.</p>
                <p>Outside of work, I immerse myself in diverse interests such as playing strategic board games, engaging in competitive tennis matches, and exploring the vast narratives of history. These activities not only enrich my personal life but also enhance my problem-solving skills, providing fresh perspectives that I bring back to my professional work.</p>
                <p>I am currently seeking opportunities to leverage my full stack development skills in new and challenging environments where I can continue to grow and contribute to impactful projects. If you’re looking for a dedicated and communicative team player who thrives on solving complex problems, let’s connect!</p>
            </div>
        </div>
    );
}

export default About;
